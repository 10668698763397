<template>
  <div>
    <div class="row">
      <div class="col-xl-12">
        <b-card>
          <h6 class="text-dark">Data Parameter Visualization</h6>
          <hr />
          <div class="">
            <b-tabs align="left">
              <b-tab active title="By Element"> <elements /> </b-tab>
              <b-tab title=" By Station"> <stasiun /> </b-tab>
            </b-tabs>
          </div>
        </b-card>
      </div>
    </div>

    <!-- <tabdataraw v-if="choosenForm == 'a'"> </tabdataraw>
    <magnet v-if="choosenForm == 'b'"> </magnet> -->
  </div>
</template>

<script>
import { BCard, BCardHeader, BContainer, BRow, BCol, BTabs, BTab } from "bootstrap-vue";
//import Swal from "sweetalert2";

import Tabdataraw from "./Componendataraw/Tabdataraw.vue";
import Stasiun from "./Componendataparameter/Bystation.vue";
import Elements from "./Componendataparameter/Byelement.vue";

export default {
  components: {
    BCard,
    BCardHeader,
    BContainer,
    BRow,
    BCol,

    BTabs,
    BTab,
    //Swal,
    //Tabdataraw,
    stasiun: Stasiun,
    Elements,
  },
  data: function() {
    return {
      backgroundLoading: "#ff8000",
      selectedForm: "",
      choosenForm: "",
      t_station: "",
      t_wmoid: "",
      t_date: "",
      option: "",
    };
  },

  methods: {
    onFetch() {
      // Swal.fire({
      //   title: "Do you want to save the changes?",
      //   showDenyButton: true,
      //   showCancelButton: true,
      //   confirmButtonText: "Save",
      //   denyButtonText: `Don't save`,
      // }).then((result) => {
      //   /* Read more about isConfirmed, isDenied below */
      //   if (result.isConfirmed) {
      //     Swal.fire("Saved!", "", "success");
      //   } else if (result.isDenied) {
      //     Swal.fire("Changes are not saved", "", "info");
      //   }
      // });

      this.choosenForm = this.selectedForm;
    },
  },
  openLoadingBackground() {
    // this.$vs.loading({ background: this.backgroundLoading, color: "#ff8000" });
    // setTimeout(() => {
    //   this.$vs.loading.close();
    // }, 5000);
  },
};
</script>

<style scoped>
.pad2 {
  padding-left: 2px;
  padding-right: 2px;
}
.ant-input {
  border: 2px solid #6e6b7b !important;
}
</style>
