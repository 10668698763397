<template>
  <div>
    <a-spin :spinning="LOADING_PAGE">
      <div class="row">
        <div class="col-xl-12">
          <b-row>
            <b-col sm="2">
              <b-form-group>
                <label>* Region</label>
                <a-select show-search :value="REGION_SELECTED" style="width: 100%" :show-arrow="true" placeholder="Type for serach ..." :filter-option="false" @search="handleSearchRegion" @change="onChangeREGION">
                  <a-select-option v-for="(region, indexRegion) in STORE_REGION_VIEW" :key="'REGION_' + indexRegion" :value="region.region_id">
                    {{ region.region_description }}
                  </a-select-option>
                </a-select>
              </b-form-group>
            </b-col>

            <b-col sm="2">
              <b-form-group>
                <label>* Provinsi</label>
                <a-select show-search :value="PROVINCE_SELECTED" style="width: 100%" :filter-option="false" @search="handleSearchProvince" @change="onChangePROVINCE">
                  <a-select-option v-for="(provinsi, indexProvinsi) in STORE_PROVINCE_VIEW" :key="'REGION_' + indexProvinsi" :value="provinsi.propinsi_id">
                    {{ provinsi.propinsi_name }}
                  </a-select-option>
                </a-select>
              </b-form-group>
            </b-col>

            <b-col sm="2">
              <b-form-group>
                <label>* Kabupaten</label>
                <a-select show-search :value="DISTRICT_SELECTED" style="width: 100%" :filter-option="false" :not-found-content="null" @search="handleSearchDistrict" @change="onChangeDISTRICT">
                  <a-select-option v-for="(district, indexDistrict) in STORE_DISTRICT_VIEW" :key="'DISTRINT_' + indexDistrict" :value="district.kabupaten_id">
                    {{ district.kabupaten_name }}
                  </a-select-option>
                </a-select>
              </b-form-group>
            </b-col>

            <b-col sm="2">
              <b-form-group label="* Element" label-for="select-station" size="sm" inline id="select-form">
                <a-select show-search :value="ELEMENT_SELECTED" style="width: 100%" :filter-option="false" :not-found-content="null" @search="handleSearchElement" @change="onChangeElement">
                  <a-select-option v-for="(rawElement, indexElement) in STORE_ELEMENT_VIEW" :key="indexElement" :value="rawElement.ae_id + '#' + rawElement.ae_attribut + '#' + rawElement.ae_form_type + '#' + rawElement.ae_label">
                    {{ rawElement.ae_label }}
                  </a-select-option>
                </a-select>
              </b-form-group>
            </b-col>

            <b-col sm="2">
              <b-form-group label="* Range Value" label-for="select-station" size="sm">
                <a-select show-search :value="RANGE_VALUE_SELECTED" style="width:100%" :filter-option="true" :not-found-content="null" @change="onChangeRangeValue">
                  <a-select-option v-for="(rawRangeValue, indexRangeValue) in STORE_RANGE_VALUE" :key="indexRangeValue" :value="rawRangeValue.KEY">
                    {{ rawRangeValue.LABEL }}
                  </a-select-option>
                </a-select>
                <div style="margin-top:5px;" v-if="['CUSTOM'].indexOf(RANGE_VALUE_SELECTED) != -1">
                  <a-input-number v-model="RANGE_AVG" placeholder="Average" @change="onChangeAvg" style="width:110px" v-if="['NONE'].indexOf(RANGE_VALUE_SELECTED) != -1" />
                  <a-input-number v-model="RANGE_MIN" placeholder="* Min" @change="onChangeMin" style="width:47%" v-if="['CUSTOM'].indexOf(RANGE_VALUE_SELECTED) != -1" /> &nbsp;
                  <a-input-number v-model="RANGE_MAX" placeholder="* Max" @change="onChangeMax" style="width:47%" v-if="['CUSTOM'].indexOf(RANGE_VALUE_SELECTED) != -1" />
                </div>
              </b-form-group>
            </b-col>

            <b-col sm="2">
              <b-form-group label="* Date" label-for="select-date" size="sm">
                <a-range-picker
                  @change="onChangeRangeDate"
                  :value="DATE_RANGE_SELECTED != null && DATE_RANGE_SELECTED != '' ? [moment(DATE_RANGE_SELECTED[0], 'YYYY-MM-DD'), moment(DATE_RANGE_SELECTED[1], 'YYYY-MM-DD')] : DATE_RANGE_SELECTED"
                />
              </b-form-group>
            </b-col>

            <b-col sm="12 mt-12" style="text-align:right;">
              <!-- <b-button v-on:click="onFetch" variant="primary" class="ml-sm-6" size="sm">Filter</b-button> -->
              <b-button @click="onProcessFetchVisualElement" variant="primary" class="ml-sm-6" size="sm">Fetch</b-button>
            </b-col>

            <b-col sm="12 mt-12">
              Note : * Is Required ( * Wajib diisi ! )
            </b-col>
          </b-row>
        </div>
      </div>
      <hr />

      <div style="margin-top:20px;margin-bottom:20px;">
        &nbsp;
      </div>
      <div>
        <b-form-group>
          <b-form-radio-group id="visualisasi_type" v-model="OPTION_VISUALISASI_SELECTED" button-variant="primary" :options="OPTION_VISUALISASI" size="sm" buttons name="radios-btn-default" @change="onSetVisualisasiType" />
        </b-form-group>
      </div>

      <div v-if="OPTION_VISUALISASI_SELECTED == 'grid'" style="border:solid 1px #ccc; padding:10px;border-radius:5px;">
        <a-table :columns="COUMN_REPORT_TABLE_DATA" :data-source="REPORT_TABLE_DATA">
          <span slot="VALUE_RAW-column" slot-scope="text, record">
            <div v-if="record.RANGE_VALUE.search('CUSTOM') != -1">
              {{ isNaN(parseFloat((record.VALUE_RAW / record.VALUE_COUNT).toFixed(2))) ? "-" : parseFloat((record.VALUE_RAW / record.VALUE_COUNT).toFixed(2)) }}
            </div>
            <div v-if="record.RANGE_VALUE == 'AVG'">
              {{ isNaN(parseFloat((record.VALUE_RAW / record.VALUE_COUNT).toFixed(2))) ? "-" : parseFloat((record.VALUE_RAW / record.VALUE_COUNT).toFixed(2)) }}
            </div>
            <div v-if="record.RANGE_VALUE == 'MAX'">
              {{ record.VALUE_RAW }}
            </div>
            <div v-if="record.RANGE_VALUE == 'MIN'">
              {{ record.VALUE_RAW }}
            </div>
          </span>
        </a-table>
      </div>

      <div v-if="OPTION_VISUALISASI_SELECTED == 'chart'" style="border:solid 1px #ccc; padding:10px;border-radius:5px;">
        <div class="row">
          <div class="col-xl-12">
            <b-row>
              <!-- <b-col sm="1" style="display: flex;justify-content: center;align-items: center;">
                 <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="warning">

                  <feather-icon
                    icon="ArrowLeftCircleIcon"
                    class="mr-50"
                  />
                  <span class="align-middle">Prev</span>
                </b-button>
              </b-col> -->
              <b-col sm="12">
                <vue-apex-charts ref="chartElement" type="bar" height="550" :options="options" :series="series" style="margin-right:50px;" />
              </b-col>
              <!-- <b-col sm="1" style="display: flex;justify-content: center;align-items: center;">
                  <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="warning">

                  <span class="align-middle">Next</span> 
                  <feather-icon
                    icon="ArrowRightCircleIcon"
                    class="ml-50"
                  />
                </b-button>
              </b-col> -->

              <b-col sm="12" style="text-align:center;padding:10px;">
                <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" @click="onPrevChart()" variant="primary">
                  <feather-icon icon="ArrowLeftCircleIcon" class="ml-50" />
                </b-button>
                <span> {{ REPORT_CHART_DATA.START + 1 }} / {{ REPORT_CHART_DATA.COUNT }} </span>
                <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" @click="onNextChart()" variant="primary">
                  <feather-icon icon="ArrowRightCircleIcon" class="ml-50" />
                </b-button>
              </b-col>
            </b-row>
          </div>
        </div>
      </div>

      <div v-if="OPTION_VISUALISASI_SELECTED == 'map'">
        <DinamicMaps @onClickMarker="onClickMarker" ref="dinamicMapComponent"></DinamicMaps>
        <a-modal :visible="visible_modal_map" title="DETAIL MARKER" @cancel="handleCancelModalMap">
          <template #footer>
            <a-button key="back" @click="handleCancelModalMap">Ok</a-button>
          </template>
          <div v-html="MODAL_MARKER_HTML"></div>
        </a-modal>
      </div>
    </a-spin>
  </div>
</template>

<script>
import { BFormRadioGroup, BCard, BCardHeader, BContainer, BRow, BCol, BFormSelect, BForm, BButton, BFormGroup, BCollapse, BFormDatepicker, BFormInput, BTabs, BTab } from "bootstrap-vue";
//import Swal from "sweetalert2";

import Tabdataparameter from "./Tabdatabyelement.vue";
//import Station from "./Componendataparameter/Bystation.vue";

import moment from "moment";
import DinamicMaps from "./../DinamicMaps.vue";

import DataMultipleQuery from "@/api/dummy/store/DataMultipleQuery";
import DataElementStation from "@/api/dummy/store/DataElement";
import DataStation from "@/api/dummy/store/DataStation";
import DataELEMENT from "@/api/dummy/store/DataDummyELEMENT";
import DataREGION from "@/api/dummy/store/DataDummyREGION";
import DataPROVINCE from "@/api/dummy/store/DataDummyPROVINCE";
import DataKABUPATEN from "@/api/dummy/store/DataDummyKABUPATEN";

import VueApexCharts from "vue-apexcharts";
import flatPickr from "vue-flatpickr-component";

export default {
  components: {
    BCard,
    BCardHeader,
    BContainer,
    BRow,
    BCol,
    BFormSelect,
    BForm,
    BButton,
    BFormGroup,
    BCollapse,
    BFormDatepicker,
    BFormInput,
    BTabs,
    BTab,
    //Swal,
    Tabdataparameter,
    VueApexCharts,
    flatPickr,
    BFormRadioGroup,
    DinamicMaps,
    //statio: Station
  },
  data: function() {
    return {
      visible_modal_map: false,
      backgroundLoading: "#ff8000",
      selectedForm: "",
      choosenForm: "",
      t_station: "",
      t_wmoid: "",
      t_date: "",
      option: "",
      STORE_RANGE_VALUE: [
        { KEY: "MIN", LABEL: "MIN" },
        { KEY: "MAX", LABEL: "MAX" },
        { KEY: "AVG", LABEL: "AVG" },
        { KEY: "CUSTOM", LABEL: "CUSTOM" },
      ],
      STORE_ELEMENT: [],
      STORE_ELEMENT_VIEW: [],
      LOADING_PAGE: false,
      ELEMENT_SELECTED: "",
      DATE_RANGE_SELECTED: null,
      RANGE_VALUE_SELECTED: "",
      SHOW_POPHOVER: false,
      RANGE_MAX: null,
      RANGE_MIN: null,
      RANGE_AVG: null,
      MODAL_MARKER_HTML: "",
      SET_MARKER: [],
      REGION_SELECTED: "",
      PROVINCE_SELECTED: "",
      DISTRICT_SELECTED: "",
      STORE_CALCULATE_DATA: {},
      STORE_RESOURCE: [],
      STORE_REGION: [],
      STORE_PROVINCE: [],
      STORE_DISTRICT: [],
      STORE_RESOURCE_VIEW: [],
      STORE_REGION_VIEW: [],
      STORE_PROVINCE_VIEW: [],
      STORE_DISTRICT_VIEW: [],
      STORE_STATION_ELEMENT: {},
      OPTION_VISUALISASI_SELECTED: "grid",
      OPTION_VISUALISASI: [
        { text: "Tabular", value: "grid" },
        { text: "Chart", value: "chart" },
        { text: "Map", value: "map" },
      ],
      COUMN_REPORT_TABLE_DATA: [
        {
          title: "ELEMENT",
          dataIndex: "ELEMENT_LABEL",
          key: "ELEMENT_LABEL",
          scopedSlots: { customRender: "ELEMENT_LABEL-column" },
        },
        {
          title: "RANGE VALUE",
          dataIndex: "RANGE_VALUE",
          key: "RANGE_VALUE",
          scopedSlots: { customRender: "RANGE_VALUE-column" },
        },
        {
          title: "VALUE",
          dataIndex: "VALUE_RAW",
          key: "VALUE_RAW",
          scopedSlots: { customRender: "VALUE_RAW-column" },
        },
        {
          title: "DATE",
          dataIndex: "DATE",
          key: "DATE",
          scopedSlots: { customRender: "DATE-column" },
          sorter: (a, b) => new Date(a.DATE) - new Date(b.DATE),
          sortDirections: ["descend", "ascend"],
          defaultSortOrder: "descend",
        },
        {
          title: "STATION ID",
          dataIndex: "WMO_ID",
          key: "WMO_ID",
          scopedSlots: { customRender: "WMO_ID-column" },
        },
        {
          title: "STATION NAME",
          dataIndex: "STATION_NAME",
          key: "STATION_NAME",
          scopedSlots: { customRender: "STATION_NAME-column" },
        },
      ],
      REPORT_TABLE_DATA: [],
      REPORT_CHART_DATA: { START: 0, COUNT: 0, RAWS: {} },
      options: {
        chart: {
          type: "bar",
          height: 550,
        },
        plotOptions: {
          bar: {
            barHeight: "100%",
            distributed: true,
            horizontal: true,
            dataLabels: {
              position: "bottom",
            },
          },
        },
        dataLabels: {
          enabled: true,
          textAnchor: "start",
          style: {
            colors: ["#fff"],
          },
          formatter: function(val, opt) {
            return opt.w.globals.labels[opt.dataPointIndex] + " :  " + val;
          },
          offsetX: 0,
          dropShadow: {
            enabled: true,
          },
        },
        stroke: {
          width: 1,
          colors: ["#fff"],
        },
        xaxis: {
          categories: [],
        },
        yaxis: {
          labels: {
            show: false,
          },
        },
        title: {
          text: "Custom DataLabels",
          align: "center",
          floating: true,
        },
        subtitle: {
          text: "",
          align: "center",
        },
        tooltip: {
          x: {
            show: false,
          },
          y: {
            style: {
              colors: ["#000"],
            },
            title: {
              formatter: function(val, opt) {
                return "" + opt.w.globals.labels[opt.dataPointIndex];
              },
            },
          },
        },
      },
      series: [
        {
          data: [],
        },
      ],
    };
  },

  methods: {
    moment,
    onSetVisualisasiType(value) {
      this.OPTION_VISUALISASI_SELECTED = value;

      if (this.OPTION_VISUALISASI_SELECTED == "grid") {
        this.onRefreshGridVisualElement();
      }

      if (this.OPTION_VISUALISASI_SELECTED == "chart") {
        this.onRefreshChartVisualElement();
      }

      if (this.OPTION_VISUALISASI_SELECTED == "map") {
        this.onRefreshMapVisualElamant();
      }
    },
    handleCancelModalMap() {
      this.visible_modal_map = false;
    },
    onFetch() {
      this.choosenForm = this.selectedForm;
    },
    onChangeElement(values) {
      this.ELEMENT_SELECTED = values;
      this.STORE_ELEMENT_VIEW = this.STORE_ELEMENT;
    },
    onChangeRangeValue(values) {
      this.RANGE_VALUE_SELECTED = values;
    },
    onChangeRangeDate(date, dateString) {
      this.DATE_RANGE_SELECTED = dateString;
    },
    onChangeAvg(values) {
      this.RANGE_AVG = values;
    },
    onChangeMin(values) {
      this.RANGE_MIN = values;
    },
    onChangeMax(values) {
      this.RANGE_MAX = values;
    },
    handleSearchRegion(search) {
      let temp = search.toLowerCase();
      var foundRaw = [];
      var REGIONS = this.STORE_REGION;
      if (search == "" || search == null) {
        this.STORE_REGION_VIEW = this.STORE_REGION;
      }
      for (var i in REGIONS) {
        var regionName = REGIONS[i].region_description
          .toString()
          .toLowerCase()
          .search(temp);
        if (regionName != -1) {
          foundRaw.push(REGIONS[i]);
        }
      }
      this.STORE_REGION_VIEW = foundRaw;
    },
    handleSearchProvince(search) {
      let temp = search.toLowerCase();
      var foundRaw = [];
      var PROVINCE = this.STORE_PROVINCE;
      if (search == "" || search == null) {
        this.STORE_PROVINCE_VIEW = this.STORE_PROVINCE;
      }
      for (var i in PROVINCE) {
        var provinceName = PROVINCE[i].propinsi_name
          .toString()
          .toLowerCase()
          .search(temp);
        if (provinceName != -1) {
          foundRaw.push(PROVINCE[i]);
        }
      }
      this.STORE_PROVINCE_VIEW = foundRaw;
    },
    handleSearchDistrict(search) {
      let temp = search.toLowerCase();
      var foundRaw = [];
      var DISTRICT = this.STORE_DISTRICT;
      if (search == "" || search == null) {
        this.STORE_DISTRICT_VIEW = this.STORE_DISTRICT;
      }
      for (var i in DISTRICT) {
        var districtName = DISTRICT[i].kabupaten_name
          .toString()
          .toLowerCase()
          .search(temp);
        if (districtName != -1) {
          foundRaw.push(DISTRICT[i]);
        }
      }
      this.STORE_DISTRICT_VIEW = foundRaw;
    },
    handleSearchElement(search) {
      let temp = search.toLowerCase();
      var foundRaw = [];
      var ELEMENT_DATA = this.STORE_ELEMENT;
      if (search == "" || search == null) {
        this.STORE_ELEMENT_VIEW = this.STORE_ELEMENT;
      }
      for (var i in ELEMENT_DATA) {
        var elementName = ELEMENT_DATA[i].ae_label
          .toString()
          .toLowerCase()
          .search(temp);
        if (elementName != -1) {
          foundRaw.push(ELEMENT_DATA[i]);
        }
      }
      this.STORE_ELEMENT_VIEW = foundRaw;
    },
    async onReloadRegion() {
      this.LOADING_PAGE = true;
      this.REGION_SELECTED = "";
      this.PROVINCE_SELECTED = "";
      this.DISTRICT_SELECTED = "";
      this.STORE_REGION = [];
      this.STORE_PROVINCE = [];
      this.STORE_DISTRICT = [];
      this.STORE_REGION = await DataREGION.callDataREGION();
      this.STORE_REGION_VIEW = this.STORE_REGION;
      this.LOADING_PAGE = false;
    },
    async onChangeREGION(values) {
      this.LOADING_PAGE = true;
      this.REGION_SELECTED = values;
      this.STORE_REGION_VIEW = this.STORE_REGION;
      this.PROVINCE_SELECTED = "";
      this.DISTRICT_SELECTED = "";
      this.STORE_PROVINCE = [];
      this.STORE_DISTRICT = [];
      this.STORE_PROVINCE = await DataPROVINCE.callDataPROVINCE(this.REGION_SELECTED);
      this.STORE_PROVINCE_VIEW = this.STORE_PROVINCE;
      this.LOADING_PAGE = false;
    },
    async onChangePROVINCE(values) {
      this.LOADING_PAGE = true;
      this.PROVINCE_SELECTED = values;
      this.STORE_PROVINCE_VIEW = this.STORE_PROVINCE;
      this.DISTRICT_SELECTED = "";
      this.STORE_DISTRICT = [];
      this.STORE_DISTRICT = await DataKABUPATEN.callDataKABUPATEN(this.PROVINCE_SELECTED);
      this.STORE_DISTRICT_VIEW = this.STORE_DISTRICT;
      this.LOADING_PAGE = false;
    },
    async onChangeDISTRICT(values) {
      this.LOADING_PAGE = true;
      this.DISTRICT_SELECTED = values;
      this.STORE_DISTRICT_VIEW = this.STORE_DISTRICT;
      this.STORE_ELEMENT = [];
      this.STORE_STATION_ELEMENT = {};

      var STATION = await DataStation.callDataStationFilter("&region_id=" + this.REGION_SELECTED + "&propinsi_id=" + this.PROVINCE_SELECTED + "&kabupaten_id=" + this.DISTRICT_SELECTED);

      var ELEMENT_AVALIABLE = [];
      var ELEMEN_SET_STORE = [];
      var STATION_IN_ELEMENT = {};

      for (var i in STATION) {
        var RAW_STATION = STATION[i];
        var REGION = RAW_STATION.region_id;
        var PROPINSI = RAW_STATION.propinsi_id;
        var DISTRICT = RAW_STATION.kabupaten_id;
        var STATION_ID = RAW_STATION.station_id;
        var ElementStation = await DataElementStation.callDataELEMENT_STATION("/" + REGION + "/" + PROPINSI + "/" + DISTRICT + "/" + STATION_ID);

        if (ElementStation["BmkgSatu.metadata.aelement.IAelement"]["aelement_data"] != null) {
          for (var I_ELM in ElementStation["BmkgSatu.metadata.aelement.IAelement"]["aelement_data"]) {
            var ELEMENT = ElementStation["BmkgSatu.metadata.aelement.IAelement"]["aelement_data"][I_ELM];
            if (ELEMENT_AVALIABLE.indexOf(ELEMENT.ae_id) == -1) {
              ELEMENT_AVALIABLE.push(ELEMENT.ae_id);
              ELEMEN_SET_STORE.push(ELEMENT);
            }

            if (STATION_IN_ELEMENT[ELEMENT.ae_id] == undefined) {
              STATION_IN_ELEMENT[ELEMENT.ae_id] = {};
            }

            if (STATION_IN_ELEMENT[ELEMENT.ae_id][STATION_ID] == undefined) {
              STATION_IN_ELEMENT[ELEMENT.ae_id][STATION_ID] = RAW_STATION;
            }
          }
        }
      }
      this.STORE_STATION_ELEMENT = STATION_IN_ELEMENT;
      console.log(this.STORE_STATION_ELEMENT);
      this.STORE_ELEMENT = ELEMEN_SET_STORE;
      this.STORE_ELEMENT_VIEW = this.STORE_ELEMENT;
      this.ELEMENT_SELECTED = "";
      this.LOADING_PAGE = false;
    },
    async onChangeELEMENT(values) {
      this.LOADING_PAGE = true;
      this.ELEMENT_SELECTED = values;
      this.STORE_ELEMENT_VIEW = this.STORE_ELEMENT;
      this.LOADING_PAGE = false;
    },
    randomIntFromInterval(min, max) {
      // min and max included
      return Math.floor(Math.random() * (max - min + 1) + min);
    },
    onClickMarker(event, params) {
      console.log(event, params);
      this.visible_modal_map = true;
      var DATA_HTML = "<table class='title-desc'>";
      DATA_HTML += "<tr>";
      DATA_HTML += "<td>STATION ID</td>";
      DATA_HTML += "<td>:</td>";
      DATA_HTML += "<td>" + params["STATION_INFO"]["WMO_ID"] + "</td>";
      DATA_HTML += "</tr>";
      DATA_HTML += "<tr>";
      DATA_HTML += "<td>STATION NAME</td>";
      DATA_HTML += "<td>:</td>";
      DATA_HTML += "<td>" + params["STATION_INFO"]["STATION_NAME"] + "</td>";
      DATA_HTML += "</tr>";
      DATA_HTML += "</table>";
      DATA_HTML += "<table class='table-desc'>";
      DATA_HTML += "<thead>";
      DATA_HTML += "<tr>";
      DATA_HTML += "<td>DATE</td>";
      DATA_HTML += "<td>ELEMENT</td>";
      DATA_HTML += "<td>RANGE</td>";
      DATA_HTML += "<td>VALUES</td>";
      DATA_HTML += "</tr>";
      DATA_HTML += "</thead>";
      DATA_HTML += "<tbody>";
      for (var i in params["DATA"]) {
        var DATA_RAW = params["DATA"][i];
        DATA_HTML += "<tr>";
        DATA_HTML += "<td>" + DATA_RAW["DATE"] + "</td>";
        DATA_HTML += "<td>" + DATA_RAW["ELEMENT_LABEL"] + "</td>";
        DATA_HTML += "<td>" + DATA_RAW["RANGE_VALUE"] + "</td>";
        DATA_HTML += "<td>" + DATA_RAW["VALUE_DATA"] + "</td>";
        DATA_HTML += "</tr>";
      }
      DATA_HTML += "</tbody>";
      DATA_HTML += "</table>";

      this.MODAL_MARKER_HTML = DATA_HTML;
    },
    async onProcessFetchVisualElement(values) {
      this.LOADING_PAGE = true;
      var ELEMENT_SELECT_ITEM = this.ELEMENT_SELECTED.split("#");

      // console.log("STATION", this.STORE_STATION_ELEMENT[ELEMENT_SELECT_ITEM[0]]);
      // console.log("ELEMENT", this.ELEMENT_SELECTED);
      // console.log("RANGE", this.RANGE_VALUE_SELECTED);
      // console.log("DATE", this.DATE_RANGE_SELECTED);
      // console.log("MAX", this.RANGE_MAX);
      // console.log("MIN", this.RANGE_MIN);

      if (this.DATE_RANGE_SELECTED == null || this.ELEMENT_SELECTED == "" || this.REGION_SELECTED == "" || this.PROVINCE_SELECTED == "" || this.DISTRICT_SELECTED == "" || this.RANGE_VALUE_SELECTED == "") {
        this.$swal({
          icon: "error",
          title: "Error!",
          text: "Please entri all filter !",
          customClass: {
            confirmButton: "btn btn-success",
          },
        });
        this.LOADING_PAGE = false;
        return;
      }

      if (this.RANGE_VALUE_SELECTED == "CUSTOM") {
        if (this.RANGE_MAX == null || this.RANGE_MIN == null) {
          this.$swal({
            icon: "error",
            title: "Error!",
            text: "Please entri amount MIN and MAX value!",
            customClass: {
              confirmButton: "btn btn-success",
            },
          });
          this.LOADING_PAGE = false;
          return;
        }
      }

      var DATA_REPORT = this.STORE_STATION_ELEMENT[ELEMENT_SELECT_ITEM[0]];

      var DATA_STATION = [];
      var STATION_SELECTED = "";
      var STATION_INFO = {};
      for (var INDEX_STATION in DATA_REPORT) {
        var RAW_REPORT = DATA_REPORT[INDEX_STATION];
        if (DATA_STATION.indexOf(RAW_REPORT.station_id) == -1) {
          DATA_STATION.push(RAW_REPORT.station_id);
          STATION_INFO[RAW_REPORT.station_id] = {};
          STATION_SELECTED += "," + RAW_REPORT.station_id;
        }

        STATION_INFO[RAW_REPORT.station_id] = {
          STATION_ID: RAW_REPORT.station_id,
          STATION_NAME: RAW_REPORT.station_name,
          WMO_ID: RAW_REPORT.station_wmo_id,
          LATITUDE: RAW_REPORT.current_latitude,
          LONGITUDE: RAW_REPORT.current_longitude,
          ELEVATION: RAW_REPORT.station_elevation,
        };
      }
      var ELEMENT_ID = ELEMENT_SELECT_ITEM[0];
      var ELEMENT_ATTR = ELEMENT_SELECT_ITEM[1];
      var ELEMENT_TYPE = ELEMENT_SELECT_ITEM[2];
      var ELEMENT_LABEL = ELEMENT_SELECT_ITEM[3];

      var PARAM_REPORT =
        "data_timestamp__gte=" +
        this.DATE_RANGE_SELECTED[0] +
        "&data_timestamp__lte=" +
        this.DATE_RANGE_SELECTED[1] +
        "&_size=1000000&type_name=" +
        ELEMENT_TYPE +
        "&station_id=" +
        STATION_SELECTED.substring(1) +
        "&_metadata=station_id,station_name,data_timestamp,id," +
        ELEMENT_ATTR;
      var DATA_REPORT_API = await DataMultipleQuery.callDataMultiQuery(PARAM_REPORT);

      if (DATA_REPORT_API.length == 0) {
        this.$swal({
          icon: "error",
          title: "Error!",
          text: "Data not found !",
          customClass: {
            confirmButton: "btn btn-success",
          },
        });
        this.LOADING_PAGE = false;
        return;
      }

      var DATA_REPORT_CALCULATE = {};
      DATA_REPORT_CALCULATE = this.STORE_CALCULATE_DATA;
      var NO_LOOP = 0;
      for (var INDEX_DATA in DATA_REPORT_API) {
        var RAW = DATA_REPORT_API[INDEX_DATA];
        var DATE_RAW = new Date(RAW.data_timestamp);
        var MONTH_RAW = DATE_RAW.getMonth() + 1;
        var DAY_RAW = DATE_RAW.getDate();
        var PERIODE = DATE_RAW.getFullYear() + "-" + "00".substring(MONTH_RAW.toString().length) + MONTH_RAW + "-" + "00".substring(DAY_RAW.toString().length) + DAY_RAW;

        var SEARCH_FORMAT = this.RANGE_VALUE_SELECTED == "CUSTOM" ? this.RANGE_VALUE_SELECTED + "(" + this.RANGE_MIN + " - " + this.RANGE_MAX + ")" : this.RANGE_VALUE_SELECTED;

        if (DATA_REPORT_CALCULATE[SEARCH_FORMAT] == undefined) {
          DATA_REPORT_CALCULATE[SEARCH_FORMAT] = {};
        }

        if (DATA_REPORT_CALCULATE[SEARCH_FORMAT][PERIODE] == undefined) {
          DATA_REPORT_CALCULATE[SEARCH_FORMAT][PERIODE] = {};
        }

        if (DATA_REPORT_CALCULATE[SEARCH_FORMAT][PERIODE][ELEMENT_ATTR] == undefined) {
          DATA_REPORT_CALCULATE[SEARCH_FORMAT][PERIODE][ELEMENT_ATTR] = {};
        }

        if (this.STORE_CALCULATE_DATA[SEARCH_FORMAT][PERIODE][ELEMENT_ATTR][RAW.station_id] != undefined) {
          delete DATA_REPORT_CALCULATE[SEARCH_FORMAT][PERIODE][ELEMENT_ATTR][RAW.station_id];
        }

        if (DATA_REPORT_CALCULATE[SEARCH_FORMAT][PERIODE][ELEMENT_ATTR][RAW.station_id] == undefined) {
          NO_LOOP = 0;
          DATA_REPORT_CALCULATE[SEARCH_FORMAT][PERIODE][ELEMENT_ATTR][RAW.station_id] = {
            STATION_ID: RAW.station_id,
            STATION_NAME: RAW.station_name,
            WMO_ID: STATION_INFO[RAW.station_id].WMO_ID,
            LATITUDE: STATION_INFO[RAW.station_id].LATITUDE,
            LONGITUDE: STATION_INFO[RAW.station_id].LONGITUDE,
            ELEVATION: STATION_INFO[RAW.station_id].ELEVATION,
            ELEMENT_ID: ELEMENT_SELECT_ITEM[0],
            ELEMENT_ATTR: ELEMENT_SELECT_ITEM[1],
            ELEMENT_TYPE: ELEMENT_SELECT_ITEM[2],
            ELEMENT_LABEL: ELEMENT_SELECT_ITEM[3],
            RANGE_VALUE: this.RANGE_VALUE_SELECTED == "CUSTOM" ? this.RANGE_VALUE_SELECTED + "[ MIN : " + this.RANGE_MIN + " , MAX : " + this.RANGE_MAX + "]" : this.RANGE_VALUE_SELECTED,
            VALUE_RAW: 0,
            VALUE_COUNT: 0,
            DATE: PERIODE,
            RAW_STORE_DATA: [],
          };
        }

        if (this.RANGE_VALUE_SELECTED == "MIN") {
          if (NO_LOOP == 0) {
            DATA_REPORT_CALCULATE[SEARCH_FORMAT][PERIODE][ELEMENT_ATTR][RAW.station_id]["VALUE_RAW"] = Number(RAW[ELEMENT_ATTR]);
          } else {
            if (Number(RAW[ELEMENT_ATTR]) < DATA_REPORT_CALCULATE[SEARCH_FORMAT][PERIODE][ELEMENT_ATTR][RAW.station_id]["VALUE_RAW"]) {
              DATA_REPORT_CALCULATE[SEARCH_FORMAT][PERIODE][ELEMENT_ATTR][RAW.station_id]["VALUE_RAW"] = Number(RAW[ELEMENT_ATTR]);
            }
          }
          NO_LOOP++;
        }

        if (this.RANGE_VALUE_SELECTED == "MAX") {
          if (NO_LOOP == 0) {
            DATA_REPORT_CALCULATE[SEARCH_FORMAT][PERIODE][ELEMENT_ATTR][RAW.station_id]["VALUE_RAW"] = Number(RAW[ELEMENT_ATTR]);
          } else {
            if (Number(RAW[ELEMENT_ATTR]) > DATA_REPORT_CALCULATE[SEARCH_FORMAT][PERIODE][ELEMENT_ATTR][RAW.station_id]["VALUE_RAW"]) {
              DATA_REPORT_CALCULATE[SEARCH_FORMAT][PERIODE][ELEMENT_ATTR][RAW.station_id]["VALUE_RAW"] = Number(RAW[ELEMENT_ATTR]);
            }
          }
          NO_LOOP++;
        }

        if (this.RANGE_VALUE_SELECTED == "AVG") {
          DATA_REPORT_CALCULATE[SEARCH_FORMAT][PERIODE][ELEMENT_ATTR][RAW.station_id]["VALUE_COUNT"] = DATA_REPORT_CALCULATE[SEARCH_FORMAT][PERIODE][ELEMENT_ATTR][RAW.station_id]["VALUE_COUNT"] + 1;
          DATA_REPORT_CALCULATE[SEARCH_FORMAT][PERIODE][ELEMENT_ATTR][RAW.station_id]["VALUE_RAW"] += Number(RAW[ELEMENT_ATTR]);
        }

        if (this.RANGE_VALUE_SELECTED == "CUSTOM") {
          if (Number(RAW[ELEMENT_ATTR]) >= Number(this.RANGE_MIN) && Number(RAW[ELEMENT_ATTR]) <= Number(this.RANGE_MAX)) {
            DATA_REPORT_CALCULATE[SEARCH_FORMAT][PERIODE][ELEMENT_ATTR][RAW.station_id]["VALUE_COUNT"] = DATA_REPORT_CALCULATE[SEARCH_FORMAT][PERIODE][ELEMENT_ATTR][RAW.station_id]["VALUE_COUNT"] + 1;
            DATA_REPORT_CALCULATE[SEARCH_FORMAT][PERIODE][ELEMENT_ATTR][RAW.station_id]["VALUE_RAW"] += Number(RAW[ELEMENT_ATTR]);
          }
        }

        DATA_REPORT_CALCULATE[SEARCH_FORMAT][PERIODE][ELEMENT_ATTR][RAW.station_id]["RAW_STORE_DATA"].push({ DATA_DATE: RAW.data_timestamp, STATION_ID: RAW.station_id, STATION_NAME: RAW.station_name, VALUE_RAW: Number(RAW[ELEMENT_ATTR]) });
      }
      this.STORE_CALCULATE_DATA = DATA_REPORT_CALCULATE;
      this.LOADING_PAGE = false;
      this.onRefreshGridVisualElement();
      this.onRefreshChartVisualElement();
      this.onRefreshMapVisualElamant();
    },
    async onRefreshMapVisualElamant() {
      this.LOADING_PAGE = true;
      var STATION_MAP = {};
      var STATION_DESC = {};
      for (var SEARCH_FORMAT in this.STORE_CALCULATE_DATA) {
        for (var PERIODE in this.STORE_CALCULATE_DATA[SEARCH_FORMAT]) {
          for (var ELEMENT_KEY in this.STORE_CALCULATE_DATA[SEARCH_FORMAT][PERIODE]) {
            for (var STATION_ID in this.STORE_CALCULATE_DATA[SEARCH_FORMAT][PERIODE][ELEMENT_KEY]) {
              var DATA_TRX = this.STORE_CALCULATE_DATA[SEARCH_FORMAT][PERIODE][ELEMENT_KEY][STATION_ID];
              var VALUES_DATA = 0;
              if (DATA_TRX.RANGE_VALUE == "MIN") {
                if (isNaN(parseFloat((DATA_TRX.VALUE_RAW * 1).toFixed(2)))) {
                  continue;
                }
                VALUES_DATA = Number(DATA_TRX.VALUE_RAW);
              }

              if (DATA_TRX.RANGE_VALUE == "MAX") {
                if (isNaN(parseFloat((DATA_TRX.VALUE_RAW * 1).toFixed(2)))) {
                  continue;
                }
                VALUES_DATA = Number(DATA_TRX.VALUE_RAW);
              }

              if (DATA_TRX.RANGE_VALUE == "AVG") {
                if (isNaN(parseFloat((DATA_TRX.VALUE_RAW / DATA_TRX.VALUE_COUNT).toFixed(2)))) {
                  continue;
                }
                VALUES_DATA = parseFloat((DATA_TRX.VALUE_RAW / DATA_TRX.VALUE_COUNT).toFixed(2));
              }

              if (DATA_TRX.RANGE_VALUE.search("CUSTOM") != -1) {
                if (isNaN(parseFloat((DATA_TRX.VALUE_RAW / DATA_TRX.VALUE_COUNT).toFixed(2)))) {
                  continue;
                }
                VALUES_DATA = parseFloat((DATA_TRX.VALUE_RAW / DATA_TRX.VALUE_COUNT).toFixed(2));
              }

              var THIS_DATA = {};
              for (var i in DATA_TRX) {
                THIS_DATA[i] = DATA_TRX[i];
              }
              THIS_DATA["VALUE_DATA"] = VALUES_DATA;

              if (STATION_MAP[STATION_ID] == undefined) {
                STATION_MAP[STATION_ID] = {};
                STATION_DESC[STATION_ID] = {
                  STATION_ID: DATA_TRX.STATION_ID,
                  STATION_NAME: DATA_TRX.STATION_NAME,
                  WMO_ID: DATA_TRX.WMO_ID,
                  LATITUDE: DATA_TRX.LATITUDE,
                  LONGITUDE: DATA_TRX.LONGITUDE,
                  ELEVATION: DATA_TRX.ELEVATION,
                };
              }

              STATION_DESC[STATION_ID] = {
                STATION_ID: DATA_TRX.STATION_ID,
                STATION_NAME: DATA_TRX.STATION_NAME,
                WMO_ID: DATA_TRX.WMO_ID,
                LATITUDE: DATA_TRX.LATITUDE,
                LONGITUDE: DATA_TRX.LONGITUDE,
                ELEVATION: DATA_TRX.ELEVATION,
              };

              var KEY_REPORT = SEARCH_FORMAT + "#" + PERIODE + "#" + ELEMENT_KEY;
              if (STATION_MAP[STATION_ID][KEY_REPORT] == undefined) {
                STATION_MAP[STATION_ID][KEY_REPORT] = {};
              }
              STATION_MAP[STATION_ID][KEY_REPORT] = THIS_DATA;
            }
          }
        }
      }

      var MARKER = [];
      for (var STATION_ID in STATION_DESC) {
        var INFO_STATION = STATION_DESC[STATION_ID];
        MARKER.push({
          position: [Number(INFO_STATION.LATITUDE), Number(INFO_STATION.LONGITUDE)],
          //JIKA INGIN MENGCUSTOME ICON
          iconSize: [15, 15],
          // iconAnchor      : [],
          // iconUrl         : "",
          markerInfo: { STATION_INFO: INFO_STATION, DATA: STATION_MAP[STATION_ID] },
        });
      }
      this.SET_MARKER = MARKER;
      setTimeout(() => {
        if (this.$refs.dinamicMapComponent) {
          this.$refs.dinamicMapComponent.generateMarker(this.SET_MARKER);
        }
        this.LOADING_PAGE = false;
      }, 3000);
    },
    async onRefreshChartVisualElement() {
      this.LOADING_PAGE = true;
      var CHART_STORE = { START: 0, COUNT: 0, RAWS: {} };
      var CHART_REPORT = {};

      for (var SEARCH_FORMAT in this.STORE_CALCULATE_DATA) {
        for (var PERIODE in this.STORE_CALCULATE_DATA[SEARCH_FORMAT]) {
          for (var ELEMENT_KEY in this.STORE_CALCULATE_DATA[SEARCH_FORMAT][PERIODE]) {
            for (var STATION_ID in this.STORE_CALCULATE_DATA[SEARCH_FORMAT][PERIODE][ELEMENT_KEY]) {
              var DATA_TRX = this.STORE_CALCULATE_DATA[SEARCH_FORMAT][PERIODE][ELEMENT_KEY][STATION_ID];

              var VALUES_DATA = 0;
              if (DATA_TRX.RANGE_VALUE == "MIN") {
                if (isNaN(parseFloat((DATA_TRX.VALUE_RAW * 1).toFixed(2)))) {
                  continue;
                }
                VALUES_DATA = Number(DATA_TRX.VALUE_RAW);
              }

              if (DATA_TRX.RANGE_VALUE == "MAX") {
                if (isNaN(parseFloat((DATA_TRX.VALUE_RAW * 1).toFixed(2)))) {
                  continue;
                }
                VALUES_DATA = Number(DATA_TRX.VALUE_RAW);
              }

              if (DATA_TRX.RANGE_VALUE == "AVG") {
                if (isNaN(parseFloat((DATA_TRX.VALUE_RAW / DATA_TRX.VALUE_COUNT).toFixed(2)))) {
                  continue;
                }
                VALUES_DATA = parseFloat((DATA_TRX.VALUE_RAW / DATA_TRX.VALUE_COUNT).toFixed(2));
              }

              if (DATA_TRX.RANGE_VALUE.search("CUSTOM") != -1) {
                if (isNaN(parseFloat((DATA_TRX.VALUE_RAW / DATA_TRX.VALUE_COUNT).toFixed(2)))) {
                  continue;
                }
                VALUES_DATA = parseFloat((DATA_TRX.VALUE_RAW / DATA_TRX.VALUE_COUNT).toFixed(2));
              }

              var KEY_REPORT = SEARCH_FORMAT + "#" + PERIODE + "#" + ELEMENT_KEY;
              if (CHART_REPORT[KEY_REPORT] == undefined) {
                CHART_STORE["COUNT"] = CHART_STORE["COUNT"] + 1;
                CHART_REPORT[KEY_REPORT] = { COUNT: 0, ELEMENT_LABEL: DATA_TRX.ELEMENT_LABEL, CATEGORIES: [], DATAS: [] };
              }
              CHART_REPORT[KEY_REPORT]["COUNT"] = CHART_REPORT[KEY_REPORT]["COUNT"] + 1;
              CHART_REPORT[KEY_REPORT]["CATEGORIES"].push(DATA_TRX.STATION_NAME);
              CHART_REPORT[KEY_REPORT]["DATAS"].push(VALUES_DATA);
            }
          }
        }
      }

      CHART_STORE["RAWS"] = CHART_REPORT;
      this.REPORT_CHART_DATA = CHART_STORE;
      this.LOADING_PAGE = false;
      this.onPrevChart();
    },
    async onNextChart() {
      this.LOADING_PAGE = true;
      var SHOW_CHART = this.REPORT_CHART_DATA;
      if (SHOW_CHART.START != SHOW_CHART.COUNT - 1) {
        SHOW_CHART.START = SHOW_CHART.START + 1;
      }
      var NO = 0;
      var CHART_LOAD = this.options;
      var SERIES_CHART = [{ data: [] }];

      for (var i in SHOW_CHART.RAWS) {
        if (NO != SHOW_CHART.START) {
          NO++;
          continue;
        }
        var INFO_SPLIT = i.split("#");
        var CHART_INFO = SHOW_CHART.RAWS[i];
        CHART_LOAD.xaxis.categories = CHART_INFO.CATEGORIES;
        SERIES_CHART = [
          {
            data: CHART_INFO.DATAS,
          },
        ];
        CHART_LOAD.title = {
          text: CHART_INFO.ELEMENT_LABEL,
          align: "center",
          floating: true,
        };
        CHART_LOAD.subtitle = {
          text: "Periode : " + INFO_SPLIT[1] + ", RANGE VALUE : " + INFO_SPLIT[0],
          align: "center",
        };
        NO++;
      }

      this.series = SERIES_CHART;
      this.options = CHART_LOAD;

      if (this.$refs.chartElement) {
        this.$refs.chartElement.updateOptions(this.options);
      }
      this.LOADING_PAGE = false;
    },
    async onPrevChart() {
      this.LOADING_PAGE = true;
      var SHOW_CHART = this.REPORT_CHART_DATA;
      if (SHOW_CHART.START != 0) {
        SHOW_CHART.START = SHOW_CHART.START - 1;
      }
      var NO = 0;
      var CHART_LOAD = this.options;
      var SERIES_CHART = [{ data: [] }];
      for (var i in SHOW_CHART.RAWS) {
        if (NO != SHOW_CHART.START) {
          NO++;
          continue;
        }
        var INFO_SPLIT = i.split("#");
        var CHART_INFO = SHOW_CHART.RAWS[i];
        CHART_LOAD.xaxis.categories = CHART_INFO.CATEGORIES;
        SERIES_CHART = [
          {
            data: CHART_INFO.DATAS,
          },
        ];
        CHART_LOAD.title = {
          text: CHART_INFO.ELEMENT_LABEL,
          align: "center",
          floating: true,
        };
        CHART_LOAD.subtitle = {
          text: "Periode : " + INFO_SPLIT[1] + ", RANGE VALUE : " + INFO_SPLIT[0],
          align: "center",
        };
        NO++;
      }

      this.series = SERIES_CHART;
      this.options = CHART_LOAD;
      if (this.$refs.chartElement) {
        this.$refs.chartElement.updateOptions(this.options);
      }
      this.LOADING_PAGE = false;
    },
    async onRefreshGridVisualElement() {
      this.LOADING_PAGE = true;
      var TABLE_REPORT = [];
      for (var SEARCH_FORMAT in this.STORE_CALCULATE_DATA) {
        for (var PERIODE in this.STORE_CALCULATE_DATA[SEARCH_FORMAT]) {
          for (var ELEMENT_KEY in this.STORE_CALCULATE_DATA[SEARCH_FORMAT][PERIODE]) {
            for (var STATION_ID in this.STORE_CALCULATE_DATA[SEARCH_FORMAT][PERIODE][ELEMENT_KEY]) {
              var DATA_TRX = this.STORE_CALCULATE_DATA[SEARCH_FORMAT][PERIODE][ELEMENT_KEY][STATION_ID];

              if (DATA_TRX.RANGE_VALUE == "MIN") {
                if (isNaN(parseFloat((DATA_TRX.VALUE_RAW * 1).toFixed(2)))) {
                  continue;
                }
              }

              if (DATA_TRX.RANGE_VALUE == "MAX") {
                if (isNaN(parseFloat((DATA_TRX.VALUE_RAW * 1).toFixed(2)))) {
                  continue;
                }
              }

              if (DATA_TRX.RANGE_VALUE == "AVG") {
                if (isNaN(parseFloat((DATA_TRX.VALUE_RAW / DATA_TRX.VALUE_COUNT).toFixed(2)))) {
                  continue;
                }
              }

              if (DATA_TRX.RANGE_VALUE.search("CUSTOM") != -1) {
                if (isNaN(parseFloat((DATA_TRX.VALUE_RAW / DATA_TRX.VALUE_COUNT).toFixed(2)))) {
                  continue;
                }
              }
              TABLE_REPORT.push(this.STORE_CALCULATE_DATA[SEARCH_FORMAT][PERIODE][ELEMENT_KEY][STATION_ID]);
            }
          }
        }
      }
      this.REPORT_TABLE_DATA = TABLE_REPORT;
      this.LOADING_PAGE = false;
    },
  },
  mounted() {
    this.onReloadRegion();
  },
  openLoadingBackground() {
    // this.$vs.loading({ background: this.backgroundLoading, color: "#ff8000" });
    // setTimeout(() => {
    //   this.$vs.loading.close();
    // }, 5000);
  },
};
</script>

<style>
.table-desc {
  font-size: 12px;
  margin-top: 10px;
  width: 100%;
  border: solid 1px #ddd;
}

.table-desc thead {
  background-color: aqua;
}
.table-desc thead tr td {
  padding: 5px;
  text-align: center;
  font-weight: bold;
}

.table-desc tbody tr td {
  padding: 5px;
}

.title-desc {
  font-size: 12px;
  width: 100%;
  font-weight: bold;
}
.title-desc tr td {
  padding: 5px;
}
</style>
