import DataHelper from "@/api/dummy/store/DataHelper";
import axios from "axios";
import Helper from "@/api/helper";
import moment from 'moment'

var SHOW_DUMMY_DATA_MULTI_QUERY= false;
export default {
    async dummyDataMultiQuery(){
        return  new Promise((resolve) => {
            var datas = [
                {"station_wmo_id" : 96001, "station_id" : "1" , "station_name" : "Stasiun Meteorologi Maimun Saleh"},
                {"station_wmo_id" : 96037, "station_id" : "10" , "station_name" : "Stasiun Geofisika Deli Serdang"},
                {"station_wmo_id" : 97072, "station_id" : "100" , "station_name" : "Stasiun Meteorologi Mutiara Sis-Al Jufri"},
                {"station_wmo_id" : 97074, "station_id" : "101" , "station_name" : "Stasiun Geofisika Palu"},
            ]
            resolve(datas);
        });
    },

    async storeDataMultiQuery(PARAM){
        return  new Promise((resolve) => {
            var url = DataHelper.URL_MULTI_QUERY(PARAM);
            var data = axios.get(url, Helper.getConfig());
            resolve(data);
        });
    },

    async callDataMultiQuery(PARAM){
        return  new Promise(async (resolve) => {
            if(SHOW_DUMMY_DATA_MULTI_QUERY == true){
                var datas = await this.dummyDataMultiQuery();
                resolve(datas);
            }else{
                var datas = await this.storeDataMultiQuery(PARAM);
                resolve(datas.data);
            }
        });
    }
}