<template>
  <div>
    <b-row>
      <b-col sm="12">
        <b-tabs align="left">
          <b-tab active title="Grid"><grid /></b-tab>
          <b-tab title="Chart" lazy><chart /></b-tab>
          <b-tab title="Maps" no-body lazy><maps /></b-tab>
        </b-tabs>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BTabs, BTab, BCard, BCol, BRow, BButton } from "bootstrap-vue";
import Maps from "./maps.vue";
import Chart from "./chart.vue";
import Grid from "./grid.vue";

export default {
  components: {
    BTabs,
    BTab,
    BButton,
    BCard,
    BCol,
    BRow,
    maps: Maps,
    grid: Grid,
    chart: Chart,
  },
};
</script>
